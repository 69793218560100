import React, { useEffect, useState } from "react";
import Loader from "../Loader/Loader";
import "./VoteButton.css";

const VoteButton = (props) => {
  const { sendVote, buttonValidationArray, isProcessingVoting, isLoading } = props;

  const [isButtonActive, setButtonActive] = useState(false);

  useEffect(()=> console.log("buttonValidationArray:",buttonValidationArray), [buttonValidationArray])

  useEffect(() => {
    if (buttonValidationArray.length === 0) {
      setButtonActive(false);
    } else {
      const isValid = buttonValidationArray.find(
        (question) => question.isValid === false
      );
      if (isValid === undefined) {
        setButtonActive(true);
      } else {
        setButtonActive(false);
      }
    }
  }, [buttonValidationArray]);

  return (
    <>
      {isButtonActive ? (
        <button type="button" className="vote-button" onClick={sendVote}>
          {isProcessingVoting ? <Loader /> : "Проголосовать"}
        </button>
      ) : (
        <button type="button" className="vote-button vote-button_disabled" disabled>
          {isProcessingVoting ? <Loader /> : "Проголосовать"}
        </button>
      )}
    </>
  );
};

export default VoteButton;
