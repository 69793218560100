import React, { useEffect, useState } from "react";
import "./CardQuestionVerticalGraphWideColumn.css";

const CardQuestionVerticalGraphWideColumn = (props) => {
  const [barLength, setBarLength] = useState(0);
  const { result, colorForColumn, votedUsers } = props;

  console.log("result graph: ", result);

  useEffect(() => {
    result > 0 ? setBarLength((result / votedUsers) * 100) : setBarLength(0);
  }, [votedUsers, result]);

  return (
    <div className={"card-question-vertical-graph-wide-column__wrapper"}>
      <div className={"card-question-vertical-graph-wide-column__columns"}>
        <svg className={"card-question-vertical-graph-wide-column__column-svg"}>
          <rect
            width={"65"}
            height={`${barLength}%`}
            fill={colorForColumn}
            x="0"
            y="0"
            rx="0"
            ry="0"
          />
        </svg>
      </div>
      <div className={"card-question-vertical-graph-wide-column__name-column"}>
        {result}
      </div>
    </div>
  );
};
export default CardQuestionVerticalGraphWideColumn;
