import React, {useState} from "react";
import './MaterialsVoteQuestion.css';
import MaterialsVoteQuestionModal from "./MaterialsVoteQuestionModal/MaterialsVoteQuestionModal";
import material_vote_row from '../../../img/Materials_vote_row.svg';
import useOnClickOutsideModal from '../../../hooks/useOnClickOutsideModal';

const MaterialsVoteQuestion = (props) => {

    const {
            materialsVoteName,
            currentMaterialsVote,
            currentMaterialsQuestion
          } = props;

    const [isMaterialsModalActive, setMaterialsModalActive] = useState(false);

    useOnClickOutsideModal(isMaterialsModalActive, () => setMaterialsModalActive(false));

    return (
            <div onClick={() => setMaterialsModalActive(!isMaterialsModalActive)} className={'materials-vote-question__wrapper'}>
                <span className={'materials-vote-question__materials-name'}>{materialsVoteName}</span>
                    <img className="materials-vote-question-select-arrow" src={material_vote_row} alt="Стрелочка открытия меню"/>
                {isMaterialsModalActive && <MaterialsVoteQuestionModal 
                    currentMaterialsQuestion={currentMaterialsQuestion} 
                    currentMaterialsVote={currentMaterialsVote}/>}
            </div>
    )
}
export default MaterialsVoteQuestion;