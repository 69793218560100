import { getEvents } from "../Api/api-service";
import { useQuery } from "@tanstack/react-query";

export const useEvents = (options) => {
  return useQuery({
    queryKey: ["events"],
    queryFn: getEvents,
    // refetchOnMount: true, || "always"
    // staleTime: 0,
    refetchOnMount: false,
    ...options,
  });
};
