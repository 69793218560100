import React, {useEffect, useState} from "react";
import './MaterialsVoteQuestionModal.css';
import MaterialsVoteQuestionModalDocuments
    from "./MaterialsVoteQuestionModalDocuments/MaterialsVoteQuestionModalDocuments";
import MaterialsVoteQuestionModalLinks from "./MaterialsVoteQuestionModalLinks/MaterialsVoteQuestionModalLinks";

const MaterialsVoteQuestionModal = React.memo((props) => {

    const {
            currentMaterialsVote,
            currentMaterialsQuestion
        } = props;

    const [typeDocument, setTypeDocument] = useState([])
    const [typeLink, setTypeLink] = useState([])

    useEffect(() => {
        let isMounted = true;
        if(currentMaterialsVote !== undefined) {
            const filteredCurrentMaterialsVoteDocument = currentMaterialsVote?.materials?.filter(elem => elem.type === 'doc');
                isMounted && setTypeDocument(filteredCurrentMaterialsVoteDocument);
            const filteredCurrentMaterialsVoteLink = currentMaterialsVote?.materials?.filter(elem => elem.type === 'link');
                 isMounted && setTypeLink(filteredCurrentMaterialsVoteLink);
        }
        return () => {
            isMounted = false;
        }
    }, [currentMaterialsVote]);

    useEffect(() => {
        let isMounted = true;
        if(currentMaterialsQuestion !== undefined) {
            const filteredCurrentMaterialsQuestionDocument = currentMaterialsQuestion.filter(elem => elem.type === 'doc');
                isMounted && setTypeDocument(filteredCurrentMaterialsQuestionDocument);
            const filteredCurrentMaterialsQuestionLink = currentMaterialsQuestion.filter(elem => elem.type === 'link');
                isMounted && setTypeLink(filteredCurrentMaterialsQuestionLink);
        }
        return () => {
            isMounted = false;
        }
    }, [currentMaterialsQuestion]);

    return (
            <div className='materials-vote-question-modal__wrapper' onClick={e => e.stopPropagation()}> 
                    {React.Children.toArray(typeDocument.map(item => {
                        return (
                            <MaterialsVoteQuestionModalDocuments
                                nameDocument={item.title}
                                valueDocument={item.value}/>
                            )
                        }))
                    }
                    {React.Children.toArray(typeLink.map(el => {
                        return (
                            <MaterialsVoteQuestionModalLinks
                                nameLink={el.value}/>
                        )
                    }))
                    }
            </div>
    )
})
export default MaterialsVoteQuestionModal;