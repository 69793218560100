import React from "react";
import './CallVotingImageModal.css';
import closeIcon from '../../../img/VotesPageBlockFilterModal_close_button.svg';

const CallVotingImageModal = (props) => {

    const {
        onCloseModal,
        selectedImage
    } = props;

    return (
        <div className="call-voting-image-modal__container">
            <div className="call-voting-image-modal">
                <div className="call-voting-image-modal__title">
                    <img onClick={onCloseModal} src={closeIcon} alt={'иконка крестик'}/>
                </div>
                <img src={selectedImage} alt={'картинка'}/>

            </div>
        </div>
    )
}
export default CallVotingImageModal;